@import '../../partials/general';
@import '../../partials/tablet/general';
@import "general";

.error-container{
  padding-bottom: 200px;
  .error-title{
    font-size: 72px;
  }
  .error-message{
    font-size: 14px;
  }
}